body {
  background-color: #D9B08C;
  padding: 0;
  margin: 0;
}

header {
  background-color: #123C69;
  padding: 20px;
}

header h1 {
  font-family: Playfair Display;
  color: #fff;
  font-size: 32px;
  font-family: sans-serif;
}

 form h4 {
  color: #123C69;
  font-size: 20px;
  font-family: sans-serif;
}

header p {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}

form {
  display: inline-block;
  text-align: left;
  padding: 20px;
  
}


label {
  width: 120px;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
}

input,
button {
 
  padding: 10px;
  font-size: 16px;
}

button[type="submit"] {
  background-color: #E85A4F;
  color: white;
  border: none;
  border-radius: 5px;
}

input[type="date"] {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #555;
  padding: 10px;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #555;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Small screens */
@media only screen and (max-width: 768px) {
  .rdrCalendarWrapper {
    font-size: 14px;
  }
  .rdrDateRangePickerWrapper {
    margin: 0 10px;
  }
}

/* Medium screens */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .rdrCalendarWrapper {
    font-size: 16px;
  }
  .rdrDateRangePickerWrapper {
    margin: 0 20px;
  }
}

/* Large screens */
@media only screen and (min-width: 1024px) {
  .rdrCalendarWrapper {
    font-size: 18px;
  }
  .rdrDateRangePickerWrapper {
    margin: 0 30px;
  }
}


.DateInput_input::-webkit-input-placeholder {
  /* for Chrome, Safari, Edge */
  font-size: 18px;
  color: black;
}
.DateInput_input:-moz-placeholder {
  /* for Firefox 18- */
  font-size: 18px;
  color: black;
}
.DateInput_input::-moz-placeholder {
  /* for Firefox 19+ */
  font-size: 18px;
  color: black;
}
.DateInput_input:-ms-input-placeholder {
  /* for IE 10+ */
  font-size: 18px;
  color: black;
}




 
